"use client";

import * as React from "react";

// @components
import { Toaster } from "sonner";

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <>
      {children}
      <Toaster richColors expand={false} position="bottom-center" />
    </>
  );
}
